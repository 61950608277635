@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 3em);
  width: 100%;
  max-width: 300px;
  background: $base_orange_color;
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
  z-index: 999;
  padding: 20px;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 0 30px #fff;
}

.show {
  display: block;
}
