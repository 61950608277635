@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.wrapper {
  background-color: $base_orange_color;
  @include flexRow;
  @include flexCenter;
  flex: 1;
  position: relative;
  font-family: "Quicksand";
  font-weight: 700;
  .links {
    @include flexRow;
    justify-content: flex-end;
    min-width: 240px;
    max-width: 300px;
    position: absolute;
    right: 50px;
    .userImg {
      width: 60px;
      min-width: 60px;
      height: 60px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.3);
      overflow: hidden;
      @include flexRow;
      @include flexCenter;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      color: #fff;
      @include flexRow;
      @include flexCenter;
      font-size: 16px;
      padding: 0 5px;
    }
    .link {
      color: #fff;
      text-decoration: none;
      font-weight: 500;
      width: 50px;
      height: 50px;
      @include flexRow;
      @include flexCenter;
      padding-top: 5px;
      padding-right: 10px;
      &:hover {
        cursor: pointer;
      }
      path,
      svg {
        fill: #fff;
      }
    }
    .activeLink {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .logoWrapper {
    a {
      @include flexRow;
      align-items: center;
      max-height: 120px;
      svg:nth-child(1) {
        max-width: 80px;
      }
      svg:nth-child(2) {
        max-width: 250px;
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: $break_point_md) {
  .wrapper {
    padding-bottom: 10px;
    justify-content: start;
    padding-left: 10px;
    .logoWrapper {
      padding-right: 70px;
      a {
        svg {
          width: 100%;
        }
      }
    }
    .links {
      // left: 0;
      right: 0;
      // bottom: 10px;
      width: 100%;
      font-weight: 900;
      .name,
      .userImg {
        display: none;
      }
    }
  }
}
