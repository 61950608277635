@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flexColumn;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  @include heightWidth100;
  h1 {
    text-transform: uppercase;
    color: #000;
    font-size: 20px;
    font-weight: 400;
  }
  .buttons {
    width: 80%;
    max-width: 60vw;
    height: 10%;
    @include flexRow;
    justify-content: center;
    align-items: center;
    button {
      text-transform: uppercase;
      max-width: 30%;
      width: fit-content;
      height: 45px;
      border-radius: 24px;
      margin: 0 10px;
    }
  }
  .content {
    @include flexColumn;
    height: 80%;
    width: 80%;
    min-height: 80%;
  }
}

@media (max-width: $break_point_md) {
  .wrapper {
    .buttons {
      width: 100%;
      button {
        min-width: fit-content;
        padding: 0 10px;
        font-size: 13px;
      }
    }
    .content {
      width: 100%;
    }
  }
}
