@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.wrapper {
  color: #000;
  line-height: 1.4;
  padding: 48px 70px 40px 100px;
  h1,
  h3 {
    @include OpenSansBold;
    color: #11223f;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 30px;
    margin-bottom: 39px;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 96px;
      height: 2px;
      background-color: $base_orange_color;
      bottom: -10px;
      left: -45px;
    }
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    margin-left: 50px;
    margin-bottom: 20px;
  }
  a {
    color: $base_orange_color;
    text-decoration: underline;
  }
}

@media (max-width: $break_point_md) {
  .wrapper {
    padding: 10px;
    h1 {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
}

.underline {
  text-decoration: underline;
}

.paragraph {
  margin: 8px 0 8px 0;
  font: 14px Helvetica;
  color: #000000;
  font-kerning: none;
}

.italic {
  font-style: italic;
}
