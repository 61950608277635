@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.inputWrapper {
  @include flexColumn;
  width: 65%;
  min-width: 300px;
  position: relative;
  font-family: 'Quicksand';
  input,
  textarea {
    margin-top: 10px;
    height: 45px;
    padding: 0 10px;
    font-family: 'Quicksand';
    font-weight: 700;
  }
  input {
    border: 2px solid $secondary_grey;
    background-color: transparent;
    border-radius: 8px;
    outline: none;
    z-index: 1;
    &:focus {
      border-color: $base_dark_teal_color;
    }
    &[type='password'] {
      // font-size: 16px;
      z-index: 1;
      margin-left: 2px;
      color: transparent;
      caret-color: #000;
      &:-webkit-autofil {
        color: transparent !important;
        background-color: rgba(255, 255, 255, 0.3) !important;
      }
    }
  }
  .passwordText {
    position: absolute;
    color: #000;
    opacity: 1;
    left: 14px;
    bottom: 15px;
    font-size: 12px;
    font-weight: 600;
    z-index: 2;
  }

  .error {
    position: absolute;
    color: $error_color;
    font-weight: 500;
    font-size: 14px;
    bottom: calc(-1em - 2px);
    left: 5px;
  }
}
.values {
  position: absolute;
  bottom: -100px;
}
label {
  color: $base_dark_teal_color;
  font-weight: 500;
}
.loginInput {
  input,
  input[type='password'] {
    border-radius: 23px;
    color: #fff;
  }
}

.forgotInput {
  input {
    padding-left: 50px;
  }
}

.forgotIcon {
  position: absolute;
  @include flexRow;
  @include flexCenter;
  background-color: $base_grey_color;
  bottom: 2px;
  left: 2px;
  height: 45px;
  width: 46px;
  border-bottom-left-radius: 6px;
  z-index: 1;
  border-top-left-radius: 6px;
  svg,
  path {
    fill: $base_dark_teal_color;
    fill-opacity: 1;
    fill-rule: inherit;
  }
}
