@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flexColumn;
  @include flexCenter;
  flex: 1 1 auto;
  @include heightWidth100;
  font-family: 'Quicksand';
  font-weight: 500;

  h1 {
    text-transform: uppercase;
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }
  form {
    position: relative;
    padding: 10px;
    width: 40%;
    min-height: 40%;
    @include flexCenter;
    @include flexColumn;
    & > div {
      margin-bottom: 15px;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .toggleWrapper {
      @include flexRow;
      justify-content: flex-start;
      align-items: center;
      width: 65%;
      min-width: 300px;
      margin: 0 0 20px;
      span {
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        text-align: center;
        margin-left: 10px;
      }
    }
    button {
      min-width: 298px;
      margin-top: 10px;
    }
  }
}
