@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flexColumn;
  flex: 1 1 auto;
  @include heightWidth100;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10%;
  .successWrapper {
    color: $base_green_color;
  }
  h1 {
    text-transform: uppercase;
    color: #000;
    font-size: 20px;
    font-weight: 400;
  }
  form {
    position: relative;
    padding: 10px;
    width: 40%;
    max-width: 430px;
    @include flexCenter;
    @include flexColumn;
    & > div {
      margin-bottom: 15px;
      width: 100%;
    }
    .toggleWrapper {
      @include flexRow;
      justify-content: flex-start;
      align-items: center;
      min-width: 300px;
      margin: 10px 0;
      span {
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        text-align: center;
        margin-left: 10px;
      }
    }
  }
  .buttonsWrapper {
    margin-top: 20px;
  }
}

@media (max-width: $break_point_md) {
  .wrapper {
    form {
      width: 100%;
    }
  }
}
