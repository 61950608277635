@import "../../../../../assets/styles/mixins.scss";
@import "../../../../../assets/styles/variables.scss";

.content {
  @include flexColumn;
  @include flexCenter;
  font-family: "Quicksand";
  font-weight: 600;
  .cardElement {
    min-width: 30%;
    min-height: 150px;
    box-shadow: 0 0 25px $base_shadow_color;
    border-radius: 10px;
    padding: 10px;
    .cardNumber {
      border: 2px solid $secondary_grey;
      background-color: transparent;
      border-radius: 8px;
      min-width: 250px;
      text-align: center;
      height: 20px;
      padding: 10px 0;
    }
  }

  h2 {
    color: $base_dark_teal_color;
  }
  .plans {
    @include flexRow;
    width: 100%;
    justify-content: space-around;

    .planInternalWrapper {
      background: #fff;
      border-radius: 10px;
      padding: 20px 15px;
      box-shadow: 0 0 25px #a7a7a7;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid transparent;
      @include flexColumn;
      align-items: center;
      justify-content: space-between;
      min-height: 350px;
      min-width: 230px;
      & > div {
        max-width: 90%;
      }
      &:hover {
        box-shadow: 0 0 25px $base_shadow_color;
        cursor: pointer;
      }
      .title {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 25px;
        font-family: "Quicksand";
        margin-bottom: 10px;
      }
      .subTitle {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        font-family: "Quicksand";
        margin-bottom: 10px;
      }
      .description {
        .additionalInfo {
          background-color: #fff;
          color: $base_dark_teal_color;
          padding: 10px;
          border-radius: 10px;
          text-transform: uppercase;
          font-size: 13px;
        }
        p {
          @include flexRow;
          align-items: center;
          line-height: 14px;
          font-size: 14px;
          vertical-align: middle;
        }

        .ball {
          fill: $base_orange_color;
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      .priceWrapper {
        @include flexColumn;
        align-items: center;
        margin-bottom: 20px;
        .amount {
          font-size: 20px;
          font-family: "Quicksand";
          margin: 0;
          text-align: center;
        }
        h3 {
          font-size: 25px;
        }
        p {
          font-weight: 400;
        }
        h3,
        p {
          font-family: "Quicksand";
          margin: 0;
        }
      }
    }
    .planWrapper {
      @include flexColumn;
      width: 31%;
      margin-right: 10px;

      &.planWrapperActive {
        .planInternalWrapper {
          background-color: $base_dark_teal_color;
          color: #fff;

          .ball {
            fill: #fff;
          }
        }
      }
    }
    .activePlan {
      border-color: $base_orange_color;
    }
  }
  .validDate {
    text-align: center;
  }
}

@media (max-width: $break_point_mdSm) {
  .content {
    h2 {
      text-align: center;
    }
    .plans {
      .planWrapper {
        width: 100%;
      }
      justify-content: flex-start;
      .planInternalWrapper {
      }
    }
  }
}
