@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.BaseLayout {
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    'm m m'
    'm m m'
    'f f f';
  grid-template-columns: 4fr 4fr 4fr;
  grid-template-rows: 1fr 10fr 1fr;
  grid-gap: 0px;
  max-height: 100vh;
  height: 100vh;
  color: rgba(0, 0, 0, 0.6);
  background-color: $base_orange_color;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'JosefinSans';
    font-weight: 700;
  }
  &:not(.withHeader):before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: 1;
    background-image: url('../../../assets/svg/paw.svg');
    width: 100%;
    height: 100%;
  }
  main {
    grid-area: m;
    flex: 1;
    @include flexColumn;
    justify-content: center;
    margin: 0 8.5vw 0 8.5vw;
    z-index: 1;
    flex-wrap: wrap;
  }

  header {
    grid-area: h;
  }

  footer {
    grid-area: f;
  }

  a {
    color: #fff;
  }
}

.withHeader {
  grid-template-areas:
    'h h h'
    'm m m'
    'f f f';
  background-color: #fff;
}

@media (max-width: $break_point_md) {
  .BaseLayout {
    position: relative;
    max-height: fit-content;
    min-height: 800px;
    &:not(.withHeader):before {
      bottom: 0;
    }
    main {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: $break_point_md) and (orientation: landscape) {
  .BaseLayout {
  }
}
