@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.scss';

.wrapper {
  @include flexColumn;
  @include flexCenter;
  flex: 1 1 auto;
  @include heightWidth100;
  .subTitle {
    font-family: 'JosefinSans';
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
  }
  .logoWrapper {
    @include flexColumn;
    align-items: center;
    max-height: 30vh;
    max-width: 100vw;
    svg:nth-child(2) {
      margin-top: 30px;
      height: 50%;
    }
  }
  form {
    position: relative;
    padding: 10px;
    width: 40%;
    min-height: 40%;
    @include flexColumn;
    align-items: center;
    & > div {
      margin-bottom: 15px;
    }
    input {
      border-color: #fff;
      &:-webkit-autofil,
      &:autofil,
      &:-internal-autofill-selected {
        background-color: rgba(255, 255, 255, 0.3) !important;
      }
      margin-top: 0;
    }
    .toggleWrapper {
      @include flexRow;
      justify-content: flex-start;
      align-items: center;
      min-width: 300px;
      margin: 10px 0;
      span {
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        text-align: center;
        margin-left: 10px;
      }
    }
  }
  .buttons {
    width: 65%;
    min-width: 300px;
    min-height: 35vh;
    @include flexColumn;
    align-items: center;
    justify-content: space-between;
    & > div {
      @include flexColumn;
      align-items: center;
      width: 100%;
    }
    .forgotLink {
      color: #fff;
      margin-top: 30px;
    }
    .isDogTrainer {
      color: #000;

      text-transform: uppercase;
    }
    .forgotLink,
    .isDogTrainer {
      font-family: 'Quicksand';
      font-weight: 700;
    }
    button{
      margin-top: 10px;
    }
  }
}

@media (max-width: $break_point_md) {
  .wrapper {
    form {
      min-width: 80%;
      width: 100%;
    }
    .logoWrapper {
      svg {
        max-width: 100%;
      }
    }
  }
}
