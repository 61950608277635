@import "../../../../../assets/styles/mixins.scss";
@import "../../../../../assets/styles/variables.scss";

.content {
  display: flex;
  justify-content: center;

  .modal {
    background: #fff;
    border-radius: 10px;
    padding: 20px 15px;
    min-width: 300px;
    box-shadow: 0 0 25px #a7a7a7;
    margin-top: 20px;

    .stateStripe {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      font-family: "Quicksand";
    }
    .button {
      margin-top: 20px;
    }
  }
}
