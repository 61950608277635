@import '../../../../../assets/styles/mixins.scss';
@import '../../../../../assets/styles/variables.scss';

.wrapper {
  @include flexColumn;
  height: 100%;
  width: 100%;
  background: linear-gradient(#e8952b, #edb46e);
  border-radius: 15px;
  .internalWrapper {
    @include flexColumn;
    flex: 1;
    @include heightWidth100;
    justify-content: space-between;
    align-items: center;
    .invitation {
      padding-top: 40px;
      height: 32%;
      @include flexColumn;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #fff;
        letter-spacing: 1px;
        font-size: 36px;
        text-align: center;
        margin-bottom: 0;
      }
      .copyIcon {
        fill: $base_dark_teal_color;
      }
      svg,
      path {
        fill: $base_dark_teal_color;
        fill-opacity: 1;
        fill-rule: inherit;
      }
      .code {
        @include flexColumn;
        @include flexCenter;

        span {
          color: #fff;
          text-transform: uppercase;
          font-size: 72px;
          font-family: 'Quicksand';
          font-weight: 900;
        }
      }
    }
    svg.dog {
      margin-top: 40px;
      margin-left: 10%;
      max-height: 50%;
    }
  }
}

@media (max-width: $break_point_md) {
  .wrapper {
    .internalWrapper {
      .invitation {
        button {
          width: 80%;
        }
        .code span {
          font-size: 2em;
        }
      }
      svg.dog {
        margin-top: 10px;
      }
    }
  }
}
