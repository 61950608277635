@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.wrapper {
  position: relative;
  height: 28px;
  width: 56px;
  border-radius: 16px;
  overflow: hidden;
  @include flexRow;
  @include flexCenter;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .toggleButton {
    flex: 1;
    @include heightWidth100;
    border: none;
    cursor: pointer;

    &:after {
      background-color: $base_dark_teal_color;
      content: '';
      @include heightWidth100;
      position: absolute;
      left: 0;
    }
    &:before {
      background-color: #fff;
      opacity: 1;
      content: '';
      @include flexRow;
      @include flexCenter;
      width: 26px;
      height: 26px;
      border-radius: 13px;
      box-sizing: border-box;
      position: absolute;
      z-index: 2;
      right: 29px;
      top: 1px;
      transition: all 0.3s ease;
    }
  }

  .disabled {
    &:after {
      background-color: $base_shadow_color;
    }
    &:before {
      right: 1px;
    }
  }
}
