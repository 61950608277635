@mixin flexRow {
  display: flex;
  flex-direction: row;
}
@mixin flexColumn {
  display: flex;
  flex-direction: column;
}
@mixin flexCenter {
  align-items: center;
  justify-content: center;
}
@mixin heightWidth100 {
  height: 100%;
  width: 100%;
}
@mixin OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

@mixin baseShadow($opacity: 0.5) {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, $opacity);
  transition: all 0.3s;
}

@mixin lineClamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}
