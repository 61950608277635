$color_main_text: #282f40;
$color_second_text: #616161;
$title_color: #392f5a;

$base_orange_color: #e68f21;
$base_violet_color: #7463cd;
$base_blue_color: #4c91d0;
$base_green_color: #60bc6f;
$base_pink_color: #bc54d8;
$base_dark_teal_color: #486c74;
$base_grey_color: #eef0f0;
$base_shadow_color: #424242;

$secondary_grey: #dcdddd;

$success_color: #51c1b1;
$success_color_hover: #38877b;
$warning_color: #f4d06f;
$warning_color_hover: #aa914d;
$error_color: #f44336;
$cancel_color: #ababab;

$main_background: #e6e6e6;

$break_point_xxl: '1650px';
$break_point_xl: '1500px';
$break_point_lgXl: '1366px';
$break_point_lg: '1024px';
$break_point_md: '960px';
$break_point_mdSm: '680px';
$break_point_sm: '480px';
$break_point_xs: '380px';
