@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.button {
  width: 100%;
  min-width: 200px;
  height: 45px;
  border-radius: 23px;
  background-color: $base_orange_color;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border: none;
  font-family: "Quicksand";
  // @include baseShadow;
  &:hover:not(.disabled) {
    @include baseShadow(0.8);
  }
  &:not(.disabled) {
    cursor: pointer;
  }
  &:active {
    opacity: 0.7;
  }

  .iconLeft {
  }
}

.loginButton {
  background-color: #fff;
  color: $base_orange_color;
}
.registerButton {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
}

.disabled {
  opacity: 0.7;
}

.activeButton {
  color: $base_dark_teal_color;
  @include flexRow;
  @include flexCenter;

  span {
    margin-right: 6px;
    margin-top: 6px;
  }
}
