@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

.wrapper {
  position: fixed;
  @include flexRow;
  display: none;
  @include flexCenter;
  flex: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);

  .modal {
    display: block;
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 3em);
    width: 100%;
    max-height: 190px;
    max-width: 300px;
    background: $base_orange_color;
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center;
    z-index: 999;
    padding: 20px;
    font-size: 24px;
    line-height: 1.5;
    font-weight: bold;
    color: #fff;
    box-shadow: 0 0 30px #fff;
    transition: all 0.5s ease-in-out;
    .cardNumber {
      background-color: #fff;
      border-radius: 6px;
      height: 20px;
      padding: 10px 5px;
    }
    .cardElement {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 100%;
      transition: all 1.5s ease-in-out;
      button {
        background-color: #fff;
        color: $base_orange_color;
        margin-top: 10px;
        width: 150px;
        min-width: 150px;
      }
      p {
        font-size: 13px;
        transition: all 0.8s ease-in-out;
        margin-top: -15px;
        margin-bottom: 15px;
        z-index: -1;
      }
      .error {
        color: $error_color;
        background-color: #fff;
        width: 100%;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        margin-top: 8px;
        margin-bottom: 0px;
        z-index: 1;
      }
    }
    .cardElement.errorElement {
      max-height: 120%;
      transition: all 1s ease-in-out;
    }
  }
}

.show {
  display: flex;
}
